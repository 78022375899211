import React, { useState } from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import l10n from '../l10n';

type HeaderProps = {
    changeName: VoidFunction;
};

const Header = ({ changeName }: HeaderProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    const changeNameWrapper = () => {
        toggleIsOpen();
        changeName();
    };

    return (
        <Navbar bg="light" fixed="top" expand={'xl'} expanded={isOpen}>
            <Navbar.Brand>
                <h1>{l10n.headerTitle}</h1>
            </Navbar.Brand>
            <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={toggleIsOpen}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                    <Nav.Link onClick={changeNameWrapper}>
                        {l10n.navChangeName}
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
