import React, { useState, useEffect, useReducer } from 'react';

import Scanner from '../components/Scanner';
import Feedback from '../components/Feedback';
import l10n from '../l10n';

import { ScanReducer, asyncScanDispatch } from '../reducers/ScanReducer';
import GatekeeperStorage from '../storages/GatekeeperStorage';

type ScanViewProps = {
    name: string;
};

const ScanView = ({ name }: ScanViewProps) => {
    const [
        { gatekeeper, success, error, statusTimeout },
        dispatch,
    ] = useReducer(ScanReducer, {
        gatekeeper: GatekeeperStorage.retrieve(),
    });

    const [scanResult, setScanResult] = useState<string | null>(
        window.location.pathname.length > 1 ? window.location.href : null
    );
    const onScan = (result: string) => setScanResult(result);

    const clearStatus = () => {
        dispatch({ type: 'CLEAR_STATUS' });
    };

    useEffect(() => {
        if (scanResult) {
            asyncScanDispatch(dispatch, scanResult, name, gatekeeper?.token);
        }
    }, [scanResult]);

    useEffect(() => {
        if (gatekeeper) {
            GatekeeperStorage.store(gatekeeper);
        }
    }, [gatekeeper]);

    useEffect(() => {
        if (statusTimeout) {
            const timer = setTimeout(clearStatus, (statusTimeout + 1) * 1000);
            return () => clearTimeout(timer);
        }
    }, [statusTimeout]);

    const venueIndication = gatekeeper
        ? `${gatekeeper.venueName}@${gatekeeper.organizerName}`
        : null;

    return (
        <>
            <h2>{l10n.venueScanTitle}</h2>
            {venueIndication && <p>{venueIndication}</p>}
            <Scanner onScan={onScan} />
            <section className="lc-gap">
                <h4>{l10n.information}</h4>
                <p>{l10n.venueScanInformation}</p>
            </section>

            {success && (
                <Feedback
                    message={success}
                    onClose={clearStatus}
                    timeout={statusTimeout}
                />
            )}
            {error && (
                <Feedback
                    message={error}
                    isError
                    onClose={clearStatus}
                    timeout={statusTimeout}
                />
            )}
        </>
    );
};

export default ScanView;
