import IGatekeeper from '../types/IGatekeeper';

const storageKey = 'GATEKEEPER';
const storage = window.localStorage;

const GatekeeperStorage = {
    store: (gatekeeper: IGatekeeper): void => {
        storage.setItem(storageKey, JSON.stringify(gatekeeper));
    },
    retrieve: (): IGatekeeper => {
        const gatekeeperString = localStorage.getItem(storageKey);
        return gatekeeperString ? JSON.parse(gatekeeperString) : undefined;
    },
    clearGatekeeper: (): void => localStorage.clear(),
};

export default GatekeeperStorage;
