export enum TOKEN_TYPE {
    ACTIVATION_TOKEN,
    COVID_PASS_TOKEN,
    TICKET_TOKEN,
    UNKNOWN_TOKEN,
}

export const getTokenType = (token: string): TOKEN_TYPE => {
    if (covidPassTokenMatch(token)) {
        return TOKEN_TYPE.COVID_PASS_TOKEN;
    }

    if (ticketTokenMatch(token)) {
        return TOKEN_TYPE.TICKET_TOKEN;
    }

    if (activationTokenMatch(token)) {
        return TOKEN_TYPE.ACTIVATION_TOKEN;
    }

    return TOKEN_TYPE.UNKNOWN_TOKEN;
};

export const parseCovidPassToken = (covidPassToken: string): string => {
    const match = covidPassTokenMatch(covidPassToken);

    if (match) {
        return match[1];
    }

    throw new TypeError('Parsing invalid covid pass token');
};

export const parseActivationToken = (activationToken: string): string => {
    const match = activationTokenMatch(activationToken);

    if (match) {
        return match[1];
    }

    throw new TypeError('Parsing invalid onboarding token');
};

export const parseTicketToken = (ticketToken: string): string => {
    const match = ticketTokenMatch(ticketToken);

    if (match) {
        return match[1];
    }

    throw new TypeError('Parsing invalid ticket token');
};

const covidPassTokenMatch = (covidPassToken: string) =>
    covidPassToken.match(/lastcall:\/\/\?covidPass=(.*)/);

const ticketTokenMatch = (ticketToken: string) =>
    ticketToken.match(/lastcall:\/\/\?ticketNumber=(.*)/);

const activationTokenMatch = (activationToken: string) =>
    activationToken.match(/https:\/\/scan.getlastcall.com\/(.*)/);
