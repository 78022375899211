import React, { useState, useEffect } from 'react';

import './App.scss';

import Header from './components/Header';

import Container from 'react-bootstrap/Container';

import SetNameView from './views/SetNameView';
import ScanView from './views/ScanView';

import IAppState from './types/IAppState';
import NameStorage from './storages/NameStorage';

const App = () => {
    const [appState, setAppState] = useState<IAppState | null>();
    const [name, setName] = useState('');

    const refreshAppState = () => {
        const name = NameStorage.retrieve();
        setName(name);
        setAppState({ hasCompletedOnboarding: true, hasCompletedName: !!name });
    };

    useEffect(() => {
        refreshAppState();
    }, []);

    if (!appState) {
        return null;
    }

    const changeName = () => {
        setAppState({ ...appState, hasCompletedName: false });
    };

    const { hasCompletedName, hasCompletedOnboarding } = appState;

    return (
        <div className="lc">
            <Header changeName={changeName} />
            <Container fluid="sm" as="main">
                {!hasCompletedName ? (
                    <SetNameView onCompleteStep={refreshAppState} name={name} />
                ) : (
                    <ScanView name={name} />
                )}
            </Container>
        </div>
    );
};

export default App;
