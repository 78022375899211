// @ts-nocheck
import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';

import Button from 'react-bootstrap/Button';
import l10n from '../l10n';

type ScannerProps = {
    onScan: (result: string) => void;
};

const timeoutTimeInMs = 30000;

const Scanner = ({ onScan }: ScannerProps) => {
    const [isScanning, setIsScanning] = useState(true);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>();
    const [cameraList, setCameraList] = useState([]);
    const [cameraId, setCameraId] = useState(undefined);

    const enumerateCameras = () => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            const cameras = devices.filter((it) => it.kind === 'videoinput');
            const samsungNormalBack = cameras.find((it) =>
                it.label.toLowerCase().startsWith('camera2 0, facing back')
            );
            console.log(samsungNormalBack);
            console.log(cameras)
            if (samsungNormalBack && !cameraId) {
                setCameraId(samsungNormalBack.deviceId);
                restartCamera();
            }
            setCameraList(cameras);
        });
    };

    const handleScan = (data: string | null) => {
        if (data) {
            onScan(data);

            if (timer) {
                clearTimeout(timer);
            }
            setTimer(setTimeout(toggleCamera, timeoutTimeInMs));
        }
    };

    const handleError = (err: any) => {
        console.error(err);
    };

    useEffect(() => {
        if (isScanning) {
            setTimer(setTimeout(toggleCamera, timeoutTimeInMs));
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isScanning]);

    const toggleCamera = () => setIsScanning(!isScanning);
    const restartCamera = () => {
        setIsScanning(false);
        setTimeout(() => setIsScanning(true), 100);
    };
    
    return (
        <>
            <div className="lc-gap w-100">
                {isScanning ? (
                    <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        constraints={{ deviceId: cameraId }}
                        onLoad={enumerateCameras}
                    />
                ) : (
                    <div className="lc-scanner-disabled">
                        {l10n.scannerCameraIsDisabled}
                    </div>
                )}
            </div>
            <div className="lc-gap">
                <h4>{l10n.scannerPowerSave}</h4>
                <Button variant="outline-primary" block onClick={toggleCamera}>
                    {isScanning
                        ? l10n.scannerDisableCamera
                        : l10n.scannerEnableCamera}
                </Button>
            </div>
            <div className="lc-gap">
                <h4>Cameras</h4>
                {cameraList.map((it) => (
                    <Button
                        variant="transparent"
                        onClick={() => {
                            setCameraId(it.deviceId);
                            restartCamera();
                        }}
                    >
                        {it.label}
                    </Button>
                ))}
            </div>
        </>
    );
};

export default Scanner;
