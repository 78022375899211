import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';

import './Feedback.scss';

type FeedbackProps = {
    message: string;
    isError?: boolean;
    onClose: VoidFunction;
    timeout?: number;
};

const Feedback = ({ message, isError, onClose, timeout }: FeedbackProps) => {
    const [className, setClassName] = useState('feedback');

    const hideFeedback = () => {
        setClassName(`${className} hide`);
    };

    useEffect(() => {
        if (timeout) {
            const timer = setTimeout(hideFeedback, timeout * 1000);
            return () => clearTimeout(timer);
        }
    }, [timeout]);

    return (
        <div className={className}>
            <Alert
                className="m-0 pb-5"
                variant={isError ? 'danger' : 'success'}
                dismissible
                onClose={onClose}
            >
                <Alert.Heading>{isError ? 'Error' : 'Success'} </Alert.Heading>
                <p>{message}</p>
            </Alert>
        </div>
    );
};

export default Feedback;
