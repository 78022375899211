const storageKey = 'NAME';
const storage = window.localStorage;

const NameStorage = {
    store: (name: string): void => {
        storage.setItem(storageKey, name);
    },
    retrieve: () => {
        const name = storage.getItem(storageKey);
        return name ? name : '';
    },
    clear: (): void => storage.removeItem(storageKey),
};

export default NameStorage;
