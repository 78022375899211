import {
    asyncActivateGatekeeper,
    asyncCheckInCovidPass,
    asyncCheckTicket,
} from '../api';
import IGatekeeper from '../types/IGatekeeper';

import {
    TOKEN_TYPE,
    getTokenType,
    parseActivationToken,
    parseTicketToken,
    parseCovidPassToken,
} from '../utils/token';

import l10n from '../l10n';

type ScanReducerState = {
    gatekeeper?: IGatekeeper;
    success?: string;
    error?: string;
    statusTimeout?: number;
};

type ScanReducerAction = {
    type:
        | 'CLEAR_STATUS'
        | 'INVALID_QR_CODE'
        | 'GATEKEEPER_ACTIVATED'
        | 'GATEKEEPER_FAILED_ACTIVATION'
        | 'GATEKEEPER_NOT_ACTIVATED'
        | 'COVID_PASS_CHECKED_IN'
        | 'COVID_PASS_FAILED_CHECK_IN'
        | 'TICKET_VALID'
        | 'TICKET_ALREADY_USED'
        | 'TICKET_VALIDATION_FAILED';
    gatekeeper?: IGatekeeper;
    error?: string;
};

export const ScanReducer = (
    state: ScanReducerState,
    action: ScanReducerAction
): ScanReducerState => {
    switch (action.type) {
        case 'CLEAR_STATUS':
            return {
                ...state,
                error: undefined,
                success: undefined,
                statusTimeout: undefined,
            };
        case 'INVALID_QR_CODE':
            return { ...state, error: l10n.errorInvalidQR, statusTimeout: 5 };

        // Activation token scan
        case 'GATEKEEPER_ACTIVATED':
            return {
                ...state,
                gatekeeper: action.gatekeeper,
                success: l10n.successGatekeeperActivated,
                statusTimeout: 5,
            };
        case 'GATEKEEPER_FAILED_ACTIVATION':
            return { ...state, error: action.error, statusTimeout: 5 };

        // Covid pass token scan
        case 'GATEKEEPER_NOT_ACTIVATED':
            return {
                ...state,
                error: l10n.errorGatekeeperNotActivated,
                statusTimeout: 5,
            };
        case 'COVID_PASS_CHECKED_IN':
            return { ...state, success: l10n.successCheckIn, statusTimeout: 5 };
        case 'COVID_PASS_FAILED_CHECK_IN':
            return { ...state, error: action.error, statusTimeout: 5 };

        // Ticket token scan
        case 'TICKET_VALID':
            return {
                ...state,
                success: l10n.successTicketValid,
                statusTimeout: 5,
            };
        case 'TICKET_ALREADY_USED':
            return {
                ...state,
                error: l10n.errorTicketAlreadyUsed,
                statusTimeout: 5,
            };
        case 'TICKET_VALIDATION_FAILED':
            return { ...state, error: action.error, statusTimeout: 5 };
    }

    return state;
};

export const asyncScanDispatch = (
    dispatch: React.Dispatch<ScanReducerAction>,
    scanResult: string,
    name: string,
    gatekeeperToken?: string
) => {
    switch (getTokenType(scanResult)) {
        case TOKEN_TYPE.ACTIVATION_TOKEN:
            handleActivationTokenScan(
                dispatch,
                parseActivationToken(scanResult),
                name
            );
            break;
        case TOKEN_TYPE.COVID_PASS_TOKEN: {
            handleCovidPassTokenScan(
                dispatch,
                gatekeeperToken,
                parseCovidPassToken(scanResult)
            );
            break;
        }
        case TOKEN_TYPE.TICKET_TOKEN: {
            handleTicketTokenScan(dispatch, parseTicketToken(scanResult));
            break;
        }
        default:
            dispatch({ type: 'INVALID_QR_CODE' });
    }
};

const handleActivationTokenScan = async (
    dispatch: React.Dispatch<ScanReducerAction>,
    activationToken: string,
    name: string
) => {
    asyncActivateGatekeeper(activationToken, name)
        .then((gatekeeper) => {
            dispatch({ type: 'GATEKEEPER_ACTIVATED', gatekeeper: gatekeeper });
        })
        .catch((reason) => {
            dispatch({
                type: 'GATEKEEPER_FAILED_ACTIVATION',
                error: reason.message,
            });
        });
};

const handleCovidPassTokenScan = (
    dispatch: React.Dispatch<ScanReducerAction>,
    gatekeeperToken: string | undefined,
    covidPassToken: string
) => {
    if (!gatekeeperToken) {
        dispatch({ type: 'GATEKEEPER_NOT_ACTIVATED' });
    } else {
        asyncCheckInCovidPass(gatekeeperToken, covidPassToken)
            .then(() => {
                dispatch({ type: 'COVID_PASS_CHECKED_IN' });
            })
            .catch((reason) => {
                dispatch({
                    type: 'COVID_PASS_FAILED_CHECK_IN',
                    error: reason.message,
                });
            });
    }
};

const handleTicketTokenScan = (
    dispatch: React.Dispatch<ScanReducerAction>,
    ticketToken: string
) => {
    asyncCheckTicket(ticketToken)
        .then((ticketResult) => {
            dispatch({
                type: ticketResult.valid
                    ? 'TICKET_VALID'
                    : 'TICKET_ALREADY_USED',
            });
        })
        .catch((reason) => {
            dispatch({
                type: 'TICKET_VALIDATION_FAILED',
                error: reason.message,
            });
        });
};
