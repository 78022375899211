import IGatekeeper from './types/IGatekeeper';

const baseurl = 'https://monolith.getlastcall.com/api';
const defaultHeaders = {
    'access-control-allow-origin': '*',
    'Content-Type': 'application/json',
};

type TicketResult = {
    valid: boolean;
};

const retrieveContent = async (res: Response) => {
    const contentType = res.headers.get('Content-Type');

    if (contentType) {
        if (contentType.startsWith('application/json')) {
            const json = await res.json();
            return json;
        }
    }

    return 'OK';
};

const asyncFetchWrapper = async (
    url: string,
    method: string,
    headers: HeadersInit,
    body?: BodyInit
) => {
    const res = await fetch(url, {
        method,
        headers,
        body,
    });

    if (res.status >= 200 && res.status <= 300) {
        const content = await retrieveContent(res);
        return content;
    }

    const error = await res.json();
    throw new Error(error);
};

export const asyncActivateGatekeeper = async (
    activationToken: string,
    name: string
): Promise<IGatekeeper> =>
    asyncFetchWrapper(
        `${baseurl}/internal/v1/covid-pass/gatekeeper`,
        'POST',
        defaultHeaders,
        JSON.stringify({ activationToken, name })
    );

export const asyncCheckInCovidPass = async (
    gatekeeperToken: string,
    covidPassToken: string
): Promise<string> =>
    asyncFetchWrapper(
        `${baseurl}/internal/v1/covid-pass/check-in`,
        'POST',
        {
            'X-LC-Gatekeeper-Token': gatekeeperToken,
            ...defaultHeaders,
        },
        JSON.stringify({ covidPassToken })
    );

export const asyncCheckTicket = async (
    ticketNumber: string
): Promise<TicketResult> =>
    asyncFetchWrapper(
        `${baseurl}/v1/ticket/scan?ticketNumber=${ticketNumber}`,
        'POST',
        defaultHeaders
    );
