export default {
    headerTitle: 'LASTCALL ADMIN',

    scannerCameraIsDisabled: 'The camera is turned off to save power',
    scannerPowerSave: 'Power save',
    scannerEnableCamera: 'Turn camera on',
    scannerDisableCamera: 'Turn camera off',

    serviceWorkerUpdating: 'Updating',

    selectScanningMode: 'Choose scanner mode',

    information: 'Information',

    ticketScan: 'Ticket scan',
    ticketScanTitle: 'Scan LastCall tickets',
    ticketScanDescription: 'Scan LastCall tickets for an event.',
    ticketScanInformation:
        'Scan a LastCall ticket to verify its genuinity. The customer will get a confirmation in the app that the ticket has been validated and cannot be used again.',

    venueScan: 'Venue scan',
    venueScanTitle: 'Scan venue code',
    venueScanDescription: 'Scan entrance to a venue.',
    venueScanInformation:
        'Scan the venue QR code provided by the venue responsible.',

    navChangeName: 'Change name',

    save: 'Save',

    name: 'Name',
    nameDescription: 'Name of the scanner',
    nameReasoning:
        'LastCall would like to have your name to prevent misue of the scanner application.',

    errorInvalidQR: 'The QR code was not recognized by our system',
    successGatekeeperActivated: 'Gatekeeper session activated',
    errorGatekeeperNotActivated:
        'You must first scan the activation code to scan covid passes',
    successCheckIn: 'Successfully registered',
    successTicketValid: 'The ticket is valid',
    errorTicketAlreadyUsed: 'The ticket has already been used',
};
