import React, { useState } from 'react';

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

import NameStorage from '../storages/NameStorage';
import l10n from '../l10n';

type SetNameViewProps = {
    onCompleteStep: VoidFunction;
    name: string;
};

const SetNameView = ({
    onCompleteStep,
    name: providedName,
}: SetNameViewProps) => {
    const [name, setName] = useState(providedName);

    const isValidName = name && name.length > 0;

    const submitName = () => {
        NameStorage.store(name);
        onCompleteStep();
    };

    return (
        <div>
            <p className="mb-4 mt-2">{l10n.nameReasoning}</p>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="name-input">
                        {l10n.name}
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    aria-describedby="name-input"
                    aria-label={l10n.nameDescription}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                />
            </InputGroup>
            <Button
                variant="success"
                disabled={!isValidName}
                block
                onClick={submitName}
            >
                {l10n.save}
            </Button>
        </div>
    );
};

export default SetNameView;
